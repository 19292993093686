// javascript/controllers/rhs_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "blocker", "blocked", "toHide", "interested" ]
  
  connect() {
    // console.log("Hello, Stimulus!", this.element)    
    this.blockedTarget.disabled = true
    this.blockerTarget.addEventListener("click", () => {
        this.unblock()
    })

    this.interestedTargets.forEach(target => {
      target.addEventListener("change", (t) => {
        this.setInterested(target.value)
      })
    })
  }

  unblock() {
    if (this.blockerTarget.checked) {
        this.blockedTarget.disabled = false
    } else {
        this.blockedTarget.disabled = true
    }
  }

  setInterested(value){
    switch(value){
      case "Geen Interesse": this.expressDisinterest(); break;
      default: this.expressInterest(); break;
    }
  }

  expressDisinterest(){
    this.hideWhenNotInterested()
    this.blockedTarget.disabled = false
  }

  expressInterest(){
    this.showWhenInterested()
    this.blockedTarget.disabled = true
    this.blockerTarget.checked = false
  }

  hideWhenNotInterested(){
    this.toHideTargets.forEach(target => {
      target.classList.add("d-none")
    })
  }

  showWhenInterested(){
    this.toHideTargets.forEach(target => {
      target.classList.remove("d-none")
    })
  }
}
